<template>
  <LayoutMainPages>
    <Loading v-if="isLoading" />
    <div v-else class="mr-s48 w-full xl:mr-0">
      <AccountDeletionMessage v-bind="message" />
    </div>
  </LayoutMainPages>
</template>

<script>
import DELETE_ACCOUNT_STEP_2 from '@/graphql/mutations/DeleteUserAccountStep2.gql';
import AccountDeletionMessage from '@/components/modal/UserDeleteAccount/AccountDeletionMessage.vue';
import Loading from '@/components/misc/Loading/index.vue';
import LayoutMainPages from '@/components/misc/Layouts/LayoutMainPages.vue';

export default {
  components: {
    AccountDeletionMessage,
    Loading,
    LayoutMainPages,
  },
  data() {
    return {
      token: null,
      result: null,
      isSucceded: null,
      isLoading: true,
    };
  },
  computed: {
    message() {
      const messages = {
        ok: {
          message: [
            this.$t('user_delete.account_deletion_confirmed_description_1'),
            this.$t('user_delete.account_deletion_confirmed_description_2'),
          ],
          title: this.$t('user_delete.account_deletion_confirmed'),
        },
        notLiquidated: {
          title: this.$t('user_delete.account_deletion_not_liquidated'),
          message: [
            this.$t('user_delete.account_deletion_not_liquidated_description'),
          ],
        },
        failed: {
          title: this.$t('user_delete.account_deletion_failed'),
          message: [this.$t('user_delete.account_deletion_failed_description')],
        },
      };

      return this.result && messages[this.result];
    },
  },
  methods: {
    async confirmDeletation() {
      try {
        await this.apolloApiCall({
          mutation: DELETE_ACCOUNT_STEP_2,
          variables: {
            token: this.token,
          },
        });
        this.result = 'ok';
      } catch (err) {
        if (err && err.error_code === 'account_not_liquidated') {
          this.result = 'notLiquidated';
        } else {
          this.result = 'failed';
        }
      } finally {
        this.isLoading = false;
      }
    },
  },
  mounted() {
    this.token = this.$route.query.token;
    this.confirmDeletation();
  },
};
</script>
