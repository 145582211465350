<template>
  <div>
    <h1 class="headline-medium">
      {{ title }}
    </h1>
    <p
      v-for="(paragraph, index) of message"
      :key="index"
      class="subheadline-x-small mt-s32"
    >
      {{ paragraph }}
    </p>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    message: {
      type: Array,
      required: true,
    },
  },
};
</script>
<style scoped></style>
